var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--sm"
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "hide-details": "auto",
        "label": item.name,
        "value": item._id
      },
      on: {
        "change": function ($event) {
          return _vm.emit(item);
        }
      },
      model: {
        value: _vm.checks,
        callback: function ($$v) {
          _vm.checks = $$v;
        },
        expression: "checks"
      }
    })], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }