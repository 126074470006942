var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-currency-field', {
    staticClass: "rounded-0",
    attrs: {
      "tile": "",
      "dense": "",
      "outlined": "",
      "hide-details": "auto",
      "min": _vm.min,
      "max": _vm.max,
      "suffix": "원",
      "label": "최소 0원"
    },
    model: {
      value: _vm.filter.prices[0],
      callback: function ($$v) {
        _vm.$set(_vm.filter.prices, 0, $$v);
      },
      expression: "filter.prices[0]"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("~")]), _c('v-col', [_c('v-currency-field', {
    staticClass: "rounded-0",
    attrs: {
      "tile": "",
      "dense": "",
      "outlined": "",
      "hide-details": "auto",
      "min": _vm.min,
      "max": _vm.max,
      "suffix": "원",
      "label": "최대 10,000,000원"
    },
    model: {
      value: _vm.filter.prices[1],
      callback: function ($$v) {
        _vm.$set(_vm.filter.prices, 1, $$v);
      },
      expression: "filter.prices[1]"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-range-slider', {
    attrs: {
      "min": _vm.min,
      "max": _vm.max,
      "hide-details": "auto"
    },
    model: {
      value: _vm.filter.prices,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "prices", $$v);
      },
      expression: "filter.prices"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "auto",
      "label": "판매"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }