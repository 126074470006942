var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt txt--xs"
  }, [_c('p', [_vm._v("용량 단위는 milliliter(ml)입니다.")]), _c('p', [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-currency-field', {
    staticClass: "rounded-0",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "auto",
      "label": "최소 0",
      "min": _vm.min,
      "max": _vm.max
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("~")]), _c('v-col', [_c('v-currency-field', {
    staticClass: "rounded-0",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "auto",
      "label": "최대 1400",
      "min": _vm.min,
      "max": _vm.max
    }
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }