<template>
    <div>
        <div class="txt txt--sm txt--dark pb-12px pb-md-16px">Filter:</div>

        <v-row class="row--sm">
            <template v-for="(filtering, index) in filterings">
                <v-col :key="index" cols="auto">
                    <filter-menu v-bind="{ filtering }" />
                </v-col>
            </template>
        </v-row>

        <v-sheet color="grey lighten-5" class="filter-sheet mt-18px mt-24px mb-30px mb-md-56px pt-12px pb-24px">
            <div class="txt txt--sm mb-8px">Show results for:</div>
            <v-row class="row--xs">
                <v-col v-for="(filter, index) in filters" :key="index" cols="auto">
                    <v-chip outlined color="grey darken-3" class="rounded-xs">
                        <span v-if="['diameter', 'length', 'width', 'height'].includes(filter.key)">{{ `${filter.key} ${filter.value}mm` }}</span>
                        <span v-if="['color', 'material', 'collection'].includes(filter.key)">{{ filter.value }}</span>
                        <v-icon class="ml-8px" @click="remove(filter)">mdi-close</v-icon>
                    </v-chip>
                </v-col>
            </v-row>
        </v-sheet>
    </div>
</template>

<script>
import FilterMenu from "@/components/client/shop/products/filter/product-list-filter/filter-menu.vue";

export default {
    components: {
        FilterMenu,
    },
    props: {
        filterings: { type: Array, default: () => [] },
    },
    data: () => ({
        filters: [],
    }),
    computed: {
        query() {
            let { ...query } = this.$route.query;

            return query;
        },
        colorFiltering() {
            return this.filterings.find(({ key }) => key.code === "Colour");
        },
        materialFiltering() {
            return this.filterings.find(({ key }) => key.code === "Material");
        },
        collectionFiltering() {
            return this.filterings.find(({ key }) => key.code === "Collections");
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            const QUERY_LIST = ["length", "diameter", "width", "height", "color", "collection", "material"];
            const query = Object.keys(this.query).filter((q) => QUERY_LIST.includes(q));

            this.filters = [];

            query.forEach((item) => {
                switch (item) {
                    case "color":
                    case "material":
                    case "collection":
                        let _items = this.query[item].split("|");
                        let filtering = [];
                        if (item === "color") filtering = this.colorFiltering;
                        if (item === "material") filtering = this.materialFiltering;
                        if (item === "collection") filtering = this.collectionFiltering;

                        _items.forEach((_item) => {
                            let value = filtering?.populateValues.find(({ _id }) => _id === _item)?.name;
                            if (value) this.filters.push({ key: item, value });
                        });
                        break;
                    default:
                        this.filters.push({ key: item, value: this.query[item] });
                }
            });
        },
        remove(item) {
            let key = item.key;
            switch (key) {
                case "color":
                case "material":
                case "collection":
                    let _items = this.query[key].split("|");
                    let filtering = [];
                    if (key === "color") filtering = this.colorFiltering;
                    if (key === "material") filtering = this.materialFiltering;
                    if (key === "collection") filtering = this.collectionFiltering;

                    let _removeItem = filtering?.populateValues.find(({ name }) => name === item.value)._id;
                    this.query[key] = _items.filter((_item) => _item !== _removeItem).join("|");

                    if (!this.query[key]) delete this.query[key];
                    break;
                default:
                    delete this.query[key];
            }
            this.$router.push({ query: this.query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
        filterings() {
            this.sync();
        },
    },
};
</script>

<style lang="scss" scoped>
.filter-sheet {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        background-color: inherit;
        height: 100%;
        width: 100vw;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: -1;
    }
}
</style>