var render = function render(){
  var _vm$filterItems, _vm$filterItems2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "rounded-0",
    attrs: {
      "hide-details": "auto",
      "dense": "",
      "outlined": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": "이름으로 검색하세요"
    },
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_vm._l(_vm.limitedItems, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "hide-details": "auto",
        "label": item.name,
        "value": item._id
      },
      on: {
        "change": function ($event) {
          return _vm.emit(item);
        }
      },
      model: {
        value: _vm.checks,
        callback: function ($$v) {
          _vm.checks = $$v;
        },
        expression: "checks"
      }
    })], 1)];
  }), ((_vm$filterItems = _vm.filterItems) === null || _vm$filterItems === void 0 ? void 0 : _vm$filterItems.length) > _vm.limit ? [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.toggleShows
    }
  }, [!_vm.shows ? _c('span', [_vm._v("+ Show more " + _vm._s(`(+ ${((_vm$filterItems2 = _vm.filterItems) === null || _vm$filterItems2 === void 0 ? void 0 : _vm$filterItems2.length) - _vm.limit})`))]) : _c('span', [_vm._v("- Show less")])])], 1)] : _vm._e()], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }