<template>
    <div class="txt txt--xs">
        <p>모든 측정 단위는 millimeter(mm)입니다.</p>

        <template v-for="(item, index) in items">
            <p :key="index">
                <span class="txt--dark mb-8px d-block">
                    {{ item.text }}
                </span>
                <v-row align="center" class="row--xxs">
                    <v-col>
                        <v-currency-field v-model="filter[index].min" dense outlined hide-details="auto" :label="`최소 ${item.min}`" class="rounded-0" @change="(value) => emit({ value, index, type: 'min' })" />
                    </v-col>
                    <v-col cols="auto">~</v-col>
                    <v-col>
                        <v-currency-field v-model="filter[index].max" dense outlined hide-details="auto" :label="`최대 ${item.max}`" class="rounded-0" @change="(value) => emit({ value, index, type: 'max' })" />
                    </v-col>
                </v-row>
            </p>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        filtering: { type: Object, default: () => ({}) },
    },
    data: () => ({
        items: [
            { text: "면적", value: "diameter" },
            { text: "길이", value: "length" },
            { text: "너비", value: "width" },
            { text: "높이", value: "height" },
        ].map((v) => ({ ...v, min: 0, max: 0 })),

        filter: Array.from({ length: 4 }).fill({ min: 0, max: 0 }),
    }),
    computed: {
        query() {
            let { ...query } = this.$route.query;
            return query;
        },
    },
    mounted() {
        this.setItems();
        this.sync();
    },
    methods: {
        setItems() {
            const { values } = this.filtering;

            this.items.forEach((item) => {
                const nums = values.map(({ value }) => +value[item.value]);
                item.min = Math.min(...nums);
                item.max = Math.max(...nums);
            });

            this.filter = this.items.map((item) => ({
                min: item.min,
                max: item.max,
            }));
        },
        emit({ value, index, type }) {
            if (type === "min") {
                // input값이 최소값보다 작거나 최대값보다 클 경우,
                if (value < this.items[index].min || this.items[index].max < value) this.filter[index].min = this.items[index].min;
            } else if (type === "max") {
                if (value < this.items[index].min || this.items[index].max < value) this.filter[index].max = this.items[index].max;
            }

            let text = this.items[index].value;
            this.$router.push({ query: { ...this.query, [text]: `${this.filter[index].min}-${this.filter[index].max}` } });
        },
        sync() {
            let { diameter = null, length = null, width = null, height = null } = this.query;
            if (diameter) this.getRange('diameter', diameter);
            if (length) this.getRange('length', length);
            if (width) this.getRange('width', width);
            if (height) this.getRange('height', height);
        },
        getRange(type, value) {
            let idx = -1;
            idx = this.items.findIndex((item) => item.value === type);
            if (idx !== -1) {
                let range = value.split("-");
                this.filter[idx].min = range[0];
                this.filter[idx].max = range[1];
            }
        },
    },
    watch: {
        filtering() {
            this.setItems();
            this.sync();
        },
    },
};
</script>