<template>
    <v-row class="row--sm">
        <v-col cols="12">
            <v-text-field v-model="searchValue" hide-details="auto" dense outlined prepend-inner-icon="mdi-magnify" placeholder="이름으로 검색하세요" class="rounded-0"></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-row class="row--sm">
                <template v-for="(item, index) in limitedItems">
                    <v-col cols="12" :key="index">
                        <v-checkbox v-model="checks" hide-details="auto" :label="item.name" :value="item._id" @change="emit(item)" />
                    </v-col>
                </template>
                <template v-if="filterItems?.length > limit">
                    <v-col cols="12">
                        <v-btn text @click="toggleShows">
                            <span v-if="!shows">+ Show more {{ `(+ ${filterItems?.length - limit})` }}</span>
                            <span v-else>- Show less</span>
                        </v-btn>
                    </v-col>
                </template>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        filtering: { type: Object, default: () => ({}) },
    },
    data: () => ({
        items: [],
        checks: [],
        shows: false,

        searchValue: "",
        limit: 3,
    }),
    computed: {
        query() {
            let { ...query } = this.$route.query;
            return query;
        },
        filterItems() {
            const regex = new RegExp(this.searchValue, "i");
            return this.items.filter((item) => regex.test(item.name));
        },
        limitedItems() {
            if(!this.filterItems) return [];
            return this.shows ? this.filterItems : this.filterItems.slice(0, this.limit);
        },
    },
    mounted() {
        this.setItems();
        this.sync();
    },
    methods: {
        setItems() {
            let { populateValues = [] } = this.filtering;
            this.items = populateValues.map(({ _id, name, value }) => ({ _id, name, value }));
        },
        emit(item) {
            let { _id } = item;
            let collections = this.query?.collection ? this.query.collection.split("|") : [];

            if (collections.includes(_id)) {
                this.query.collection = collections.filter((collection) => collection !== _id).join("|");
                if (!this.query.collection) delete this.query.collection;
            } else {
                this.query.collection = [...collections, _id].join("|");
            }

            this.$router.push({ query: { ...this.query } });
        },
        sync() {
            let { collection = "" } = this.query;
            this.checks = collection.split("|").map((_collection) => this.items.find(({ _id }) => _collection === _id)?._id);
        },
        toggleShows() {
            this.shows = !this.shows;
        },
    },
    watch: {
        query() {
            this.sync();
        },
        filterItems() {
            this.sync();
        },
        filtering() {
            this.setItems();
            this.sync();
        }
    },
};
</script>