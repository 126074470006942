var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn-toggle', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.buttons,
      callback: function ($$v) {
        _vm.buttons = $$v;
      },
      expression: "buttons"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    var _item$value;
    return [_c('v-btn', {
      key: index,
      staticClass: "pa-0",
      attrs: {
        "width": "44"
      },
      on: {
        "click": function ($event) {
          return _vm.emit(item);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [item !== null && item !== void 0 && item.image ? _c('v-avatar', {
      staticClass: "mx-auto",
      attrs: {
        "size": "30"
      }
    }, [_c('v-img', {
      attrs: {
        "alt": "John",
        "src": item === null || item === void 0 ? void 0 : item.image.url
      }
    })], 1) : _c('v-avatar', {
      staticClass: "mx-auto",
      class: (item === null || item === void 0 ? void 0 : item.name) === 'White' ? 'border' : '',
      style: `background: ${(_item$value = item.value) === null || _item$value === void 0 ? void 0 : _item$value.color};`,
      attrs: {
        "size": "30"
      }
    }, [_c('v-icon', {
      staticClass: "font-size-20",
      attrs: {
        "color": (item === null || item === void 0 ? void 0 : item.name) === 'White' ? 'black' : 'white'
      }
    }, [_vm._v("mdi-check")])], 1), _c('div', {
      staticClass: "mt-8px font-size-12"
    }, [_c('div', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "grey--text text--lighten-2"
    }, [_vm._v("(" + _vm._s((item === null || item === void 0 ? void 0 : item.count) || 0) + ")")])])], 1)])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }