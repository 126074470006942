<template>
    <div class="txt txt--xs">
        <p>용량 단위는 milliliter(ml)입니다.</p>
        <p>
            <v-row align="center" class="row--xxs">
                <v-col>
                    <v-currency-field dense outlined hide-details="auto" label="최소 0" :min="min" :max="max" class="rounded-0"></v-currency-field>
                </v-col>
                <v-col cols="auto">~</v-col>
                <v-col>
                    <v-currency-field dense outlined hide-details="auto" label="최대 1400" :min="min" :max="max" class="rounded-0"></v-currency-field>
                </v-col>
            </v-row>
        </p>
    </div>
</template>

<script>
export default {
    filtering: { type: Object, default: () => ({}) },
    data: () => ({
        min: 0,
        max: 1400,
    })
};
</script>