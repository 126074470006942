var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt txt--xs"
  }, [_c('p', [_vm._v("모든 측정 단위는 millimeter(mm)입니다.")]), _vm._l(_vm.items, function (item, index) {
    return [_c('p', {
      key: index
    }, [_c('span', {
      staticClass: "txt--dark mb-8px d-block"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('v-row', {
      staticClass: "row--xxs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', [_c('v-currency-field', {
      staticClass: "rounded-0",
      attrs: {
        "dense": "",
        "outlined": "",
        "hide-details": "auto",
        "label": `최소 ${item.min}`
      },
      on: {
        "change": function (value) {
          return _vm.emit({
            value,
            index,
            type: 'min'
          });
        }
      },
      model: {
        value: _vm.filter[index].min,
        callback: function ($$v) {
          _vm.$set(_vm.filter[index], "min", $$v);
        },
        expression: "filter[index].min"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v("~")]), _c('v-col', [_c('v-currency-field', {
      staticClass: "rounded-0",
      attrs: {
        "dense": "",
        "outlined": "",
        "hide-details": "auto",
        "label": `최대 ${item.max}`
      },
      on: {
        "change": function (value) {
          return _vm.emit({
            value,
            index,
            type: 'max'
          });
        }
      },
      model: {
        value: _vm.filter[index].max,
        callback: function ($$v) {
          _vm.$set(_vm.filter[index], "max", $$v);
        },
        expression: "filter[index].max"
      }
    })], 1)], 1)], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }