<template>
    <v-row class="row--sm">
        <v-col cols="12">
            <v-row align="center" class="row--xxs">
                <v-col>
                    <v-currency-field v-model="filter.prices[0]" tile dense outlined hide-details="auto" :min="min" :max="max" suffix="원" label="최소 0원" class="rounded-0"></v-currency-field>
                </v-col>
                <v-col cols="auto">~</v-col>
                <v-col>
                    <v-currency-field v-model="filter.prices[1]" tile dense outlined hide-details="auto" :min="min" :max="max" suffix="원" label="최대 10,000,000원" class="rounded-0"></v-currency-field>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-range-slider v-model="filter.prices" :min="min" :max="max" hide-details="auto"></v-range-slider>
        </v-col>
        <v-col cols="12">
            <v-checkbox hide-details="auto" label="판매" />
        </v-col>
    </v-row>
</template>

<script>
export const initPriceFilter = (form = {}) => {
    return {
        // name: form?.name || null,
        // _seller: form?._seller || null,
        // brand: form?.brand || null,
        prices: form?.prices || [0, 10000000],
        // exceptSoldOut: form?.exceptSoldOut || true,
    };
};

export default {
    props:{ 
        filtering: { type: Object, default: () => ({}) },
    },
    data: () => ({
        min: 0,
        max: 10000000,
        filter: initPriceFilter(),
    }),
};
</script>