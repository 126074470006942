<template>
    <v-row class="row--sm">
        <template v-for="item in items">
            <v-col cols="12" :key="item">
                <v-checkbox hide-details="auto" :label="item.name" />
            </v-col>
        </template>
    </v-row>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                name: "식기세척기 사용 안전",
            },
            {
                name: "손 세척 권장",
            },
        ],
    }),
};
</script>