var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    staticClass: "pa-0"
  }, [_c('v-list-item-group', _vm._l(_vm.items, function (item, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(item.name)
      }
    })], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }