<template>
    <v-list class="pa-0">
        <v-list-item-group>
            <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    props:{ 
        filtering: { type: Object, default: () => ({}) },
    },
    data: () => ({
        items: [
            {
                name: "카테고리 이름1",
            },
            {
                name: "카테고리 이름2",
            },
            {
                name: "카테고리 이름3",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
</style>