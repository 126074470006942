<template>
    <v-row class="row--sm">
        <template v-for="(item, index) in items">
            <v-col cols="12" :key="index">
                <v-checkbox v-model="checks" hide-details="auto" :label="item.name" :value="item._id" @change="emit(item)"/>
            </v-col>
        </template>
    </v-row>
</template>

<script>
export default {
    props: {
        filtering: { type: Object, default: () => ({}) },
    },
    data: () => ({
        items: [],
        checks: []
    }),
    computed: {
        query() {
            let { ...query } = this.$route.query;
            return query;
        },
    },
    mounted() {
        this.setItems();
        this.sync();
    },
    methods: {
        setItems() {
            let { populateValues = [] } = this.filtering;
            this.items = populateValues.map(({ _id, name, value }) => ({ _id, name, value }));
        },
        emit(item) {
            let { _id } = item;
            let materials = this.query?.material ? this.query.material.split("|") : [];

            if (materials.includes(_id)) {
                this.query.material = materials.filter((material) => material !== _id).join("|");
                if (!this.query.material) delete this.query.material;
            } else {
                this.query.material = [...materials, _id].join("|");
            }

            this.$router.push({ query: { ...this.query } });
        },
        sync() {
            let { material = "" } = this.query;
            this.checks = material.split("|").map((_material) => (this.items.find(({ _id }) => _material === _id))?._id);
        },
    },
    watch: {
        query() {
            this.sync();
        },
        filtering() {
            this.setItems();
            this.sync();
        }
    },
};
</script>