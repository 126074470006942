<template>
    <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" class="product-item">
        <div class="product-item__inner">
            <like-btn :params="{ _product: value?._id }">
                <template #activator="{ active, toggle }">
                    <v-btn icon small color="grey lighten-3" class="product-item__favorite" @click.stop.capture="toggle">
                        <span v-if="active" class="material-icons-outlined font-size-20 font-size-md-24 primary--text">favorite</span>
                        <span v-else class="material-icons-outlined font-size-20 font-size-md-24 grey--text text--darken-4">favorite_border</span>
                    </v-btn>
                </template>
            </like-btn>
            <router-link :to="`/shop/products/${value._id}`">
                <div class="product-item__img" :style="`background-image:url(${value.thumb?.url});`">
                    <div v-if="!value.sells || value.stock == 0" class="product-item__badge">Sold Out</div>
                </div>
                <div class="product-item__con">
                    <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="value?.flags?.length">
                        {{ value?.flags?.map((text) => `[${text}]`).join(" ") }}
                    </div>
                    <div class="product-item__tit">
                        <span>{{ value.name }} {{ value.code || "" }}</span>
                    </div>
                    <div class="mt-10px mt-md-16px">
                        <span v-if="discountPrice" class="font-size-14 grey--text text--lighten-1 text-decoration-line-through">{{ value.price.format() }}</span>
                        <em v-if="!value.sells || value.stock == 0" class="font-size-14 font-size-md-16 grey--text text--lighten-1">품절</em>
                        <div v-else class="d-flex flex-wrap align-center font-size-18 font-size-lg-24 font-weight-bold">
                            <span v-if="discountRate" class="secondary--text mr-4px mr-md-8px">{{ discountRate }}%</span>
                            <span class="d-flex flex-wrap align-center grey--text text--darken-4">{{ salePrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></span>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
    </v-col>
</template>

<script>
import { mapGetters } from "vuex";

import LikeBtn from "@/components/client/like/like-btn.vue";

export default {
    components: {
        LikeBtn,
    },
    props: {
        value: { type: Object, default: null },

        cols: { type: String, default: "6" },
        sm: { type: String, default: "4" },
        md: { type: String, default: "4" },
        lg: { type: String, default: "3" },
    },
    computed: {
        ...mapGetters(["getDiscountPrice", "getDiscountRate", "getSalePrice"]),
        discountPrice() {
            return this.getDiscountPrice(this.value);
        },
        discountRate() {
            return this.getDiscountRate(this.value);
        },
        salePrice() {
            return this.getSalePrice(this.value);
        },
    },
};
</script>
